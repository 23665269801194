<template>
  <div style="min-width: 100%" class="company-shortlists-container" v-if="init">
    <!-- Left sidebar -->
    <div class="shortlists-selection">
      <button @click="createShortlist">
        <span class="material-icons">add</span>
        Create new shortlist
      </button>

      <div class="shortlists-filter">
        <h4>Filter by accountowner</h4>
        <div class="shortlists-dropdown">
          <div
            class="selected"
            @click="dropdownOpen = !dropdownOpen"
            :class="{ open: dropdownOpen }"
          >
            <span v-if="filter === 'all'">All</span>
            <span v-else>{{ getUserNameByVismaId(filter) }}</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M9 12.75l3 3m0 0l3-3m-3 3v-7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </div>
          <div class="options" v-if="dropdownOpen">
            <div
              class="option"
              @click="selectFilter('all')"
              :class="{ active: 'all' === filter }"
            >
              All
            </div>
            <div
              class="option"
              v-for="option in getAdminUsers"
              :key="'admin-' + option.id"
              @click="selectFilter(option.vismaId)"
              :class="{ active: option.vismaId === filter }"
            >
              {{ option.name }}
            </div>
          </div>
        </div>
      </div>

      <!-- Existing shortlists -->
      <CompanyShortlistsSelector />
    </div>

    <!--- Edit shortlist -->
    <div class="shortlist-edit" v-if="selectedShortlist !== null">
      <!-- Header -- Name + Delete -->
      <CompanyShortlistsHeader />

      <div
        class="company-shortlists-selected-company"
        v-if="companyId !== null"
      >
        <h3>
          Company:
          <span class="name">{{ getCompanyNameByVismaId(companyId) }}</span>
          <span
            @click="$store.commit('companyShortlists/setCompanyId', null)"
            class="material-icons"
            >edit</span
          >
        </h3>
      </div>
      <CompanyShortlistsCompanySelection v-else />

      <div class="shortlists-main-area">
        <!-- <SharedShortlistsCompanySelection v-if="currentStep === 0" /> -->
        <CompanyShortlistsProductSelection />
      </div>

      <CompanyShortlistsFooter />
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  name: "CompanyShortlists",
  data() {
    return {
      dropdownOpen: false,
    };
  },
  computed: {
    ...mapState({
      selectedShortlist: (state) => state.companyShortlists.selectedShortlist,
      companyId: (state) => state.companyShortlists.companyId,
      init: (state) => state.companyShortlists.init,
      filter: (state) => state.companyShortlists.filter,
    }),
    ...mapGetters("settings", ["getCompanyNameByVismaId"]),
    ...mapGetters("users", ["getUserNameByVismaId", "getAdminUsers"]),
  },
  async created() {
    if (!this.init) {
      await this.$store.dispatch("companyShortlists/bindShortlists");
      this.$store.commit("companyShortlists/setInit", true);
    }
  },
  async beforeDestroy() {
    this.$store.commit("companyShortlists/setInit", false);
    await this.$store.dispatch("companyShortlists/unbindShortlists");
  },
  components: {},
  methods: {
    selectFilter: function (selected) {
      this.dropdownOpen = false;
      console.log(selected);
      this.$store.commit("companyShortlists/setFilter", selected);
    },
    createShortlist: function () {
      this.$store.dispatch("companyShortlists/createShortlist").then(() => {});
    },
  },
};
</script>
